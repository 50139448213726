<template>

  <!-- Edit Opp Panel-->
  <div id="edit-opp-panel">

    <!-- Opp Form -->
    <OppForm class="mb-4">
      
      <!-- Actions -->
      <template #actions>

        <!-- Save -->
        <j-button
          :label="opp.isNew() ? 'Create' : 'Save Changes'"
          :processing="opp.saving"
          :disabled="opp.pristine"
          @action="handleSave"
        />
      </template>

      <!-- Options -->
      <template #options>

        <!-- Cancel -->
        <j-button
          v-if="opp.pristine"
          label="Cancel"
          type="option"
          @action="handleCancel"
        />

        <!-- Reset -->
        <j-button
          v-else-if="!opp.saving && !opp.isNew()"
          label="Reset"
          type="option"
          :disabled="opp.pristine"
          @action="handleReset"
        />

      </template>
    </OppForm>
  </div>
</template>

<script>

// Components
import OppForm from '@/components/Opps/OppForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('opps')

export default {
  name: 'EditOppPanel',
  components: {
    OppForm
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.name === 'opps-create') {
        // Initialize opp
        vm.NEW_OPP()
      }
    })
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'saveOpp'
    ]),
    // Mutations
    ...mapMutations([
      'NEW_OPP',
      'RESET_OPP'
    ]),
    // Handlers
    handleCancel () {
      this.$router.back()
    },
    handleReset() {
      this.RESET_OPP()
    },
    handleSave () {
      this.saveOpp().then(() => {
        this.$router.push({ name: 'opps-details', params: { tag19: this.opp.get('tag19') }})
      })
    }
  }
}
</script>
