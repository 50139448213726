<template>

  <!-- Opp Form -->
  <j-card
    :title="formTitle"
    class="opp-form"
  >

    <!-- Opp Form -->
    <j-card-text>
      <j-form model="opps.opp">
        <v-col cols="9">
          <v-row>

            <!-- Section -->
            <j-control
              name="section_slug"
              sm="12"
            />

            <!-- Title -->
            <j-control
              name="title"
              sm="12"
            />
          </v-row>
        </v-col>

        <!-- Badge -->
        <j-control
          name="badge_src"
          :metadata="opp.cdnMetadata"
          aspect-ratio="1"
          cols="3"
        />

        <!-- Description -->
        <j-control
          name="description"
          rows="8"
        />

        <!-- Tags -->
        <j-control name="tags" />

        <!-- Link / URL -->
        <j-control
          name="url"
          sm="6"
        />

        <!-- Release Date -->
        <j-control
          name="released_on"
          sm="6"
        />

        <!-- Contact -->
        <j-control
          name="contact"
          sm="6"
        />

        <!-- Assignment -->
        <j-control
          name="assignment_slug"
          sm="6"
        />
      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Options -->
    <template #options>
      <slot name="options" />
    </template>

  </j-card>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('opps')

export default {
  name: 'OppForm',
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp'
    ]),
    // UI
    formTitle() {
      return this.opp.isNew() ? '' : 'Edit Opportunity'
    }
  }
}
</script>
